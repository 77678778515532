import Api from "@/services/api";
import {removeEmptyParams, downloadData} from "@utils";

export default {
    findAll(filter) {
        return Api().get("/users", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findAllWithGroups(filter) {
        return Api().get("/userswithgroups", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findById(id) {
        return Api().get("/user/" + id).then(res => res.data);
    },
    findByIdWithGroups(id) {
        return Api().get("/userwithgroups/" + id).then(res => res.data);
    },
    create(user) {
        return Api().post("/user", user).then(res => res.data);
    },
    createWithGroups(userDTO) {
        return Api().post("/userwithgroups", userDTO).then(res => res.data);
    },
    update(user) {
        return Api().put("/user/" + user.id, user).then(res => res.data);
    },
    updateWithGroups(userDTO) {
        return Api().put("/userwithgroups/" + userDTO.id, userDTO).then(res => res.data);
    },
    delete(user) {
        return Api().delete("/user/" + user.id).then(() => user);
    },
    exportList() {
        return Api().get("/user/export/list", {responseType: 'blob'}).then(res => downloadData(res));
    },
    importUsers(file) {
        let formData = new FormData();
        formData.append("file", file);
        return Api().post("/user/import", formData).then(res => res);
    },
    downloadTemplate() {
        return Api().get("/user/import/template", {responseType: 'blob'}).then(res => downloadData(res));
    },
    formatRes(e) {
        return e;
    }

}