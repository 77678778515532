<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: "Title",
    },
    classTitle: {
      type: String,
      required: false,
      default: "col-lg-6 col-8"
    }
  },
}
</script>
<template>
  <div :class="classTitle" class="form-group">
    <h5 class="card-title">{{ title }}</h5>
  </div>
</template>