import Api from "@/services/api";
import {removeEmptyParams} from "@utils";

export default {
    findAll(filter) {
        return Api().get("/usergroups", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findById(id) {
        return Api().get("/usergroup/" + id).then(res => res.data);
    },
    create(user) {
        return Api().post("/usergroup", user).then(res => res.data);
    },
    update(user) {
        return Api().put("/usergroup/" + user.id, user).then(res => res.data);
    },
    delete(user) {
        return Api().delete("/usergroup/" + user.id).then(() => user);
    },
    formatRes(e) {
        return e;
    }

}